<template>
  <div class="planimageswrp">
    <draggable
      v-if="planImages"
      v-model="planImages"
      class="draggableimages"
      :class="{'disabled': !enabled}"
      ghost-class="imageghost"
      :disabled="!enabled"
      @end="updateImages"
    >
      <DraggablePlansImage
        v-for="image in planImages"
        :key="image.id"
        :image="image"
        :block="block"
        :progress="Math.round(imageUploadProgress[image.id])"
        :prospect="prospect"
        :project="project"
      ></DraggablePlansImage>
    </draggable>
    <div v-else class="alert alert-warning">
      <i class="fas fa-exclamation-triangle padding-right-6"></i> {{ trans('prospects.landingpage.carousel_empty') }}
    </div>
    <div v-cloak class="uploadField" :class="{'dragactive': dragging}">
      <i class="far fa-file-upload"></i>
      <p>{{ trans('prospects.landingpage.drag_files_to_upload') }}</p>
      <b-form-file
        v-model="formFiles"
        accept="image/jpeg, image/png, image/gif"
        multiple
        :disabled="uploadDisabled"
        @input="addFiles"
        @dragenter="dragging=true"
        @dragend="dragging=false"
        @dragleave="dragging=false"
      ></b-form-file>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { mapState } from 'vuex'
  import draggable from 'vuedraggable'
  import DraggablePlansImage from './DraggablePlansImage'

  const fb = require('../../../../../../../firebaseConfig')

  export default {
    name: 'LandingpageBlockPlans',
    components: {
      DraggablePlansImage,
      draggable
    },
    props: {
      block: {
        type: Object,
        required: true
      },
      project: {
        type: Object,
        required: true
      },
      prospect: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        planImages: this.formatImagesArray(this.block.images),
        formFiles: [],
        uploadingImages: [],
        enabled: true,
        uploadDisabled: false,
        dragging: false,
        imageUploadProgress: {},
        nextBlockKEYs: {},
        popoverShow: false,
        popoverId: 0
      }
    },
    computed: {
      ...mapState({
        userProfile: state => state.auth.userProfile
      })
    },
    watch: {
      // eslint-disable-next-line object-shorthand
      block: function (val) {
        this.planImages = this.formatImagesArray(val.images)
      }
    },
    methods: {
      formatImagesArray (val) {
        if (val !== undefined) {
          const imagesArray = []

          Object.keys(val).map((objKey) => {
            const objData = val[objKey]

            if (objData.deleted === undefined || objData.deleted === false) {
              imagesArray.push(objData)
            }
          })

          return imagesArray.sort((a, b) => {
            if (a.order !== undefined && b.order !== undefined) {
              return a.order - b.order
            } else {
              return false
            }
          })
        } else {
          return []
        }
      },
      updateImages () {
        const self = this
        this.enabled = false // Disable drag'n'drop while saving data

        const updateQuery = {}

        let count = 0
        this.planImages.forEach((image) => {
          updateQuery[`landingpage.blocks.${this.block.key}.images.${image.id}.order`] = count
          count++
        })

        console.log(updateQuery)

        fb.prospectsCollection.doc(this.prospect._id).update(updateQuery).then(() => {
          self.$bvToast.toast(self.trans('prospects.landingpage.reorder_images_success'), {
            title: self.trans('global.success'),
            variant: 'success',
            solid: true
          })
          this.enabled = true
        }).catch((err) => {
          self.$bvToast.toast(self.trans('prospects.landingpage.reorder_images_error'), {
            title: self.trans('global.error'),
            variant: 'warning',
            solid: true
          })
          console.log(err)
          this.enabled = true
        })
      },
      addFiles (data) {
        console.log('addFiles () is running')
        console.log(data)
        const droppedFiles = data
        if (droppedFiles.length === 0) return

        ([...droppedFiles]).forEach((f) => {
          this.uploadingImages.push(f)
        })

        this.dragging = false

        this.upload()
      },
      upload () {
        this.uploadDisabled = true
        const self = this

        // Check if block exists in DB - valid key required
        this.checkIfBlockExistsInDB().then(() => {
          // Create DB entries for all images in uploadingImages to get progress-placeholders
          return new Promise((resolve, reject) => {
            if (this.prospect.landingpage.blocks !== undefined &&
              Object.keys(this.prospect.landingpage.blocks).length > 0 &&
              this.prospect.landingpage.blocks[this.block.key]) {
              let nextImageId = this.prospect.landingpage.blocks[this.block.key].images !== undefined &&
                Object.keys(this.prospect.landingpage.blocks[this.block.key].images).length > 0
                ? parseInt(Object.keys(this.prospect.landingpage.blocks[this.block.key].images).pop()) + 1
                : 0

              const updateQuery = {}

              this.uploadingImages.forEach((imageFile, index) => {
                self.nextBlockKEYs[index] = nextImageId // Store for use when uploading images

                updateQuery[`landingpage.blocks.${this.block.key}.images.${nextImageId}`] = {
                  id: nextImageId,
                  createdOn: new Date(),
                  userId: this.userProfile.uid,
                  order: nextImageId,
                  deleted: false
                }

                nextImageId++
              })

              fb.prospectsCollection.doc(this.prospect._id).update(updateQuery).then(() => {
                resolve()
              }).catch((err) => {
                console.log('There was an error while creating the image DB entries, quitting...')
                console.log(err)
              })
            } else {
              console.log('Blocks data are missing from the DB, quitting...')
              // eslint-disable-next-line prefer-promise-reject-errors
              reject()
            }
          })
        }).then(() => {
          Promise.all(this.uploadingImages.map((image, index) => this.uploadFile(image, index))).then(() => {
            self.formFiles = []
            self.imageUploadProgress = {} // Reset the uploadprogressArray
            self.uploadDisabled = false
            self.uploadingImages = [] // Reset the uploadfiles
            self.nextBlockKEYs = {} // Reset the tmp object
          }).catch((err) => {
            console.log('There was an error while uploading the images')
            console.log(err)
          })
        })
      },
      // checkIfBlockExistsInDB (): "Standard" / Required blocks may not be in DB upon creation...
      checkIfBlockExistsInDB () {
        return new Promise((resolve, reject) => {
          if (this.block.key !== undefined) {
            resolve(true)
          } else {
            const nextBlockId = this.prospect.landingpage !== undefined &&
              this.prospect.landingpage.blocks !== undefined &&
              Object.keys(this.prospect.landingpage.blocks).length > 0
              ? parseInt(Object.keys(this.prospect.landingpage.blocks).pop()) + 1
              : 0

            return fb.prospectsCollection.doc(this.prospect._id).update({
              [`landingpage.blocks.${nextBlockId}`]: {
                createdOn: new Date(),
                deleted: false,
                name: this.block.name.charAt(0).toUpperCase() + this.block.name.slice(1), // Ensure correct naming (camel)
                sortable: false,
                actions: false,
                userId: this.userProfile.uid
              }
            }).then(() => {
              resolve()
            }).catch((err) => {
              reject(err)
            })
          }
        })
      },
      uploadFile (imagefile, arrayIndex) {
        const self = this
        console.log('UploadingFile:')
        if (imagefile && this.prospect !== undefined && this.project !== undefined && this.block.key !== undefined) {
          // projects/[project-VARYCODE]/prospects/[prospect-VARYCODE]/landingpage/block-[blockKEY]/images/[filename].ext
          const orgFilename = imagefile.name
          const newFilename = this.randomTxt(16) + '.' + imagefile.name.split('.').pop()
          const imageTargetPath = 'projects/' + this.project.varycode + '/prospects/' + this.prospect.varycode + '/landingpage/block-' + this.block.key + '/images'

          const storageRef = fb.storage.ref()
          const metadata = {
            contentType: imagefile.type
          }

          // Prepare a image
          const uploadTask = storageRef.child(imageTargetPath + '/' + newFilename).put(imagefile, metadata) // Upload the file

          return new Promise((resolve, reject) => {
            uploadTask.on('state_changed', function (snapshot) {
              Vue.set(self.imageUploadProgress, self.nextBlockKEYs[arrayIndex], (snapshot.bytesTransferred / snapshot.totalBytes) * 100)
              switch (snapshot.state) {
                case 'paused':
                  console.log('Upload is paused')
                  break
                case 'running':
                  console.log('Upload is running')
              }
            }, function (error) {
              let err = ''
              switch (error.code) {
                case 'storage/unauthorized':
                  err = 'Storage autorization required, access denied...'
                  break
                case 'storage/canceled':
                  err = 'The upload was cancelled'
                  break
                case 'storage/unknown':
                  err = 'An unknown error occurred during upload'
                  break
              }
              reject(new Error(err))
            }, function () {
              delete self.imageUploadProgress[self.nextBlockKEYs[arrayIndex]]

              const imgUpdQuery = {}
              const idx = self.nextBlockKEYs[arrayIndex] // key.arridx
              imgUpdQuery[`landingpage.blocks.${self.block.key}.images.${idx}.file`] = newFilename
              imgUpdQuery[`landingpage.blocks.${self.block.key}.images.${idx}.orgFile`] = orgFilename
              imgUpdQuery[`landingpage.blocks.${self.block.key}.images.${idx}.path`] = imageTargetPath
              imgUpdQuery[`landingpage.blocks.${self.block.key}.images.${idx}.metadata`] = metadata

              fb.prospectsCollection.doc(self.prospect._id).update(imgUpdQuery).then(() => {
                self.$bvToast.toast(self.trans('prospects.landingpage.carousel_image_upload_success'), {
                  title: self.trans('global.success'),
                  variant: 'success',
                  solid: true
                })
                resolve()
              }).catch((err) => {
                self.$bvToast.toast(self.trans('prospects.landingpage.carousel_image_upload_success'), {
                  title: self.trans('global.success'),
                  variant: 'success',
                  solid: true
                })
                console.log('There was an error while saving the imagedata to the DB, quitting...')
                console.log(err)
                // eslint-disable-next-line prefer-promise-reject-errors
                reject()
              })
            })
          })
        } else {
          console.log('Error: Missing imagefile-, prospect- or projectdata')
          // eslint-disable-next-line prefer-promise-reject-errors
          return Promise.reject()
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  .planimageswrp {
    padding: 16px;

    .draggableimages {
      display: flex;
      flex-wrap: wrap;

      &.disabled {
        background-color: #ffc373;
      }
    }

    .uploadField {
      position: relative;
      background-color: #f8f8f8;
      background-image: linear-gradient(45deg, #ededed 25%, #f8f8f8 25%, #f8f8f8 50%, #ededed 50%, #ededed 75%, #f8f8f8 75%, #f8f8f8 100%);
      background-size: 56.57px 56.57px;
      text-align: center;
      border: 1px dashed #eeeeee;
      border-radius: 6px;
      color: #c3c3c3;

      &.dragactive, &:hover {
        background-color: #67C18D;
        background-image: linear-gradient(45deg, #46ab72 25%, #67c18d 25%, #67c18d 50%, #46ab72 50%, #46ab72 75%, #67c18d 75%, #67c18d 100%);
        background-size: 56.57px 56.57px;
        border: 1px dashed #67C18D;
        color: white;
      }

      i {
        margin-top: 20px;
        font-size: 60px;
      }

      p {
        line-height: 90px;
        margin: 0;
      }

      /deep/ .b-form-file {
        cursor: pointer;
        position: absolute;
        opacity: 0;
        left: 0;
        top: 0;
        bottom:0;
        width: 100%;
        height: auto;

        .custom-file-input {
          cursor: pointer;
          width: 100%;
          height: 100%;
        }
      }
    }
  }
</style>
